const globalGolfCAProd = {
  search: {
    inputBox: "#searchForm .saytitbox",
    queryBtn: "#searchForm .searchsaytbtn",
    urlParam: "term",
  },

  productClickGrid: {
    productElemWrapper:
      "#aspnetForm[action*='/search/?'] .fltrt .s-fit .catprod",
    productPidAttr: "data-pid",
    source: "PRODUCT_CLICK_GRID",
  },
  addToCartFromPDP: {
    addToCartBtnSelector: "#actions #AddToCart",
    pidSelector: "#actions #AddToCart",
    productPidAttr: "data-pid",
    isVariant: true,
    vidSelector: "#main-content .s-fit .mrg-10 #filters",
    productVidAttr: "data-curr",
    source: "ADD_TO_CART_PDP",
  },
  addToCartFromCartPage: {
    cartItemWrapper: "#cart .product",
    productPidAttr: "data-pid",
    qtySelector: ".cnt .qtyupdate",
    isVariant: true,
    productVidAttr: "data-id",
    source: "ADD_TO_CART_FROM_CART_PAGE",
  },
  pageView: {
    events: {
      home: {
        debounceDelay: 1500,
        urlIdentifiers: {
          urls: ["https://www.globalgolf.ca/"],
          exactMatch: true,
        },
      },
      search: {
        uniqueSelectors: {
          selectors: ["#aspnetForm[action*='/search/?']"],
        },
        searchRecall: {
          noOfResultsSelector: "#ctl00_cplhMain_cpDisplay_ddlSorts .txtrt",
          getSRCountCB: function (str) {
            const match = str.match(/\b(\d+)\sitems\b/);
            if (match && match[1]) {
              return match[1];
            }
            return null;
          },
        },
      },
      productDisplay: {
        uniqueSelectors: {
          selectors: [".s-fit #prodTitle"],
        },
      },
      cart: {
        uniqueSelectors: {
          selectors: ["#fullcart"],
        },
        urlIdentifiers: {
          urls: ["/cart"],
          exactMatch: false,
        },
      },
    },
  },
};

export default globalGolfCAProd;